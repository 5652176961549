import React from 'react'
import { useHistory } from 'react-router-dom'
const Sect10 = () => {
    const history = useHistory()
    return (
        <section className="sect-10">
            <img src="/img/img-sect-10.png" alt="" />
            <div className="text">
                <h2 className="desktop">“ Diffuser son évènement<br/>n’a jamais été aussi simple ”</h2>
                <h2 className="mobile">“ Diffuser son évènement n’a jamais été aussi simple ”</h2>
                {/* <h2>Ils ont choisi Time N’Joy<br />pour leurs évènements</h2> */}
                <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>Référencer mon évènement</a>
            </div>
        </section>
    )
}

export default Sect10

import React from 'react'
import Button from '../../Home_api/sections/button'


const GridBlock = ({ image, title, text }) => {
    return <div className='grid-item'>
        <img src={image} alt="" />
        <h6>{title}</h6>
        <p>{text}</p>
    </div>
}
const blocks = [
    {
        title: "Community management",
        image: '/img/sect-4-2.png',
        text: " Timenjoy Studio s'occupe de la gestion de vos réseaux sociaux afin de créer un lien privilégié avec vos participants."
    },
    {
        title: "Vidéos et Snack content",
        image: '/img/sect-4-6.png',
        text: "Nous créons  des formats vidéos dynamiques de vos évènements, optimisés pour chaque réseau social."
    },
    {
        title: "SEA / SOCIAL ADS",
        image: '/img/sect-4-1.png',
        text: "Avec Timenjoy, billetterie rime avec ROI. Timenjoy s’occupe de gérer et piloter vos campagnes d’acquisition."
    },
    {
        title: "Création graphique",
        image: '/img/sect-4-7.png',
        text: " Les créations graphiques sont les premiers éléments qui vont représenter vos évènements auprès du grand public. Ils doivent être reconnaissables et mémorables."
    },
    {
        title: "Site web",
        image: '/img/sect-4-8.png',
        text: "Votre site web doit représenter votre univers et votre image afin d’inciter les visiteurs à y participer."
    },
    {
        title: "Communiqué de presse",
        image: '/img/sect-4-4.png',
        text: "La presse sera un vecteur de trafic non négligeable. Il est donc essentiel de lui communiquer des points clés à travers un bon copyrighting. (Communiqué de presse)"
    },
    {
        title: "Campagnes e-mail",
        image: '/img/sect-4-3.png',
        text: "La gestion des campagnes email est trop souvent négligée alors que ces derniers représentent un canal de conversion significatif."
    },
    {
        title: "Article de blog",
        image: '/img/sect-4-5.png',
        text: "La plume Timenjoy pour faire parler de votre événement et renforcer votre référencement naturel (SEO)."
    },
]
const Sect4 = () => {
    return (
        <section className="sect-4" id="services">
            <div className="text"><h2>Nos services</h2></div>
            {blocks.map(a => <GridBlock {...a} />)}
            <div className='button'>
                <Button text="Prendre rendez-vous" target="#calendly" />
            </div>
        </section>

    )
}

export default Sect4

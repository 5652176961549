import React from 'react';
import Hero from './sections/hero'
import Sect2 from './sections/sect2'
import Sect3 from './sections/sect3'
import Sect4 from './sections/sect4'
import Sect5 from './sections/sect5'
import Sect6 from './sections/sect6'
import Sect13 from './sections/sect13'
import Sect10 from './sections/sect10'
import Sect12 from '../Home/sections/sect12'
import Header from './sections/header'
import '../Home/home.scss'
import './home.scss'

/**
 * It's a function that returns a React component that renders the Home page
 */
const Home = () => {
    /* It's a function that returns a React component that renders the Home page */
    React.useEffect(() => {
        document.querySelector('#root').style = "height:unset;display:unset;overflow:unset;"
        return () => { document.querySelector('#root').style.height = "height:100vh;display:flex;overflow:hidden;" }
    }, [])
    return (
        <>
            <Header />
            <main className="api">
                <Hero />
                <Sect2 />
                <Sect3 />
                <Sect4 />
                <Sect5 />
                <Sect10 />
                {/* <Sect6 /> */}
                <Sect13 />
                <Sect12 text={[
                    "Mytimenjoy permet aux professionnels de l’évènementiel de reférencer et les diffuser sur l’ensemble des solutions Timenjoy.",
                    "Chaque année, ce sont plus de 500 000 évènements référencés permettant de valoriser l’offre évènementielle des territoires.",
                    "Mytimenjoy est une innovation soutenue par la French Tech, BPI, le WEB Summit & les CES de Las VEGAS."
                ]} />
            </main>
        </>
    )
}

export default Home;
import React, { useState } from 'react'
import './inputPass.scss'

/**
 * It's a function that returns a div with an input and an image
 * @returns A div with a class of password-input and a class of whatever className is passed in.
 */
const PassWordInput = ({ className, onChange, onClick, name = '', id, placeholder = '', onVisibilityChange, autocomplete = 'on', value, otherProps }) => {
    const [passwordVisibility, setVisibility] = useState(false)
    /**
     * If the argument is truthy, return it, otherwise log it
     */
    const f = a => a || console.log

    return <div className={'password-input ' + (className || '')}>
        {/* It's an input that has an onChange, onClick, type, name, id, value, otherProps, placeholder,
        and autoComplete. */}
        <input onChange={f(onChange)} onClick={f(onClick)} type={passwordVisibility ? 'text' : 'password'} name={name} {...(id ? { id } : {})} {...(value !== undefined ? { value } : {})} {...(otherProps ? { otherProps } : {})} placeholder={placeholder} autoComplete={autocomplete} />
        {/* It's setting the visibility of the password to the opposite of what it is. */}
        <img src='/img/password.svg' onClick={() => setVisibility(a => !a) || f(onVisibilityChange)(!passwordVisibility)} />
    </div>
}

export default PassWordInput
import React from 'react'

const Sect7 = () => {
    return (
        <section className="sect-7">
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
            <img src="/img/img-sect-7.jpeg" alt="" />
            <div className="text">
                <h2>Nous fonctionnons<br />à la performance</h2>
                <div className="stack">
                    <div>
                        <img src="/img/sect-7-icon-1.svg" alt="" />
                        <span>
                            <h6>Une logique Roiste</h6>
                            <p>La data est au coeur de notre accompagnement.</p>
                        </span>
                    </div>
                    <div>
                        <img src="/img/sect-7-icon-2.svg" alt="" />
                        <span>
                            <h6>Une diffusion multicanale</h6>
                            <p>Diffusez vos évènements sur un grand nombre de supports.</p>
                        </span>
                    </div>
                    <div>
                        <img src="/img/sect-7-icon-4.svg" alt="" />
                        <span>
                            <h6>Transparence des résultats</h6>
                            <p>Analysez vos résultats en temps réel.</p>
                        </span>
                    </div>
                    <div>
                        <img src="/img/sect-7-icon-3.svg" alt="" />
                        <span>
                            <h6>Payez au résultats</h6>
                            <p>Payez uniquement en fonction de votre objectif.</p>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Sect7

import React from 'react'
import Button from '../../Home_api/sections/button'

const Sect6 = () => {
    return (
        <section className="sect-6">
            <h2>Comment ça marche ?</h2>
            <div className="row">
                <div>
                    <img src="/img/block-4-1.svg" alt="" />
                    <div>
                        <h6>Prenez rendez-vous</h6>
                        <p>
                            Définissez le créneau qui vous convient et prenez un rendez-vous avec une personne de l’équipe afin d’identifier vos besoins et objectifs
                        </p>
                    </div>
                </div>
                <div>
                    <img src="/img/block-4-2.svg" alt="" />
                    <div>
                        <h6>Définissez votre audience et vos canaux</h6>
                        <p>
                            En fonction de votre budget et objectifs, notre solution identifie l’audience intéressée par vos évènements et sélectionnons ensemble les canaux de diffusion
                        </p>
                    </div>
                </div>
                <div>
                    <img src="/img/block-4-3.svg" alt="" />
                    <div>
                        <h6>Définissez la durée de la campagne</h6>
                        <p>
                            Ensemble, nous identifions la durée de campagne idéale pour votre évènement et les différentes étapes
                        </p>
                    </div>
                </div>
                <div>
                    <img src="/img/block-4-4.svg" alt="" />
                    <div>
                        <h6>
                            Analysez vos performances
                        </h6>
                        <p>
                            Laissez Timenjoy piloter votre campagne multicanale en toute sérénité et analysez les performances de votre campagne en temps réel grâce à votre tableau de bord.
                        </p>
                    </div>
                </div>
            </div>
            <Button text="Prendre rendez-vous" />
        </section>
    )
}

export default Sect6

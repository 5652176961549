import React from 'react'
import PopupWrapper from '../../../components/UI/PopupWrapper'
import { Link } from 'react-router-dom'
const Menu = ({ open = false, close, className, closeCross }) => {
    return (
        <PopupWrapper open={open} close={close} className={className} closeCross={closeCross} >
            <nav>
                <h4>Timenjoy Pro</h4>
                <Link to="/home">Mytimenjoy</Link>
                <Link to="/performance">Timenjoy Performance</Link>
                <Link to="/studio">Timenjoy Studio</Link>
                {/* <Link to="/connect">Timenjoy Connect</Link> */}
            </nav>
            <nav>
                <h4>Communauté</h4>
                <a href="https://timenjoy.fr">Site Internet</a>
                <a href="https://www.blog.timenjoy.fr">Blog</a>
                <a href="https://m.me/timenjoyapp?ref=Welcome%20message">Chatbot</a>
            </nav>
            <nav>
                <h4>Réseaux Sociaux</h4>
                <a href="https://www.facebook.com/search/top?q=timenjoy">Facebook</a>
                <a href="https://instagram.com/time_n_joy?utm_medium=copy_link">Instagram</a>
                <a href="https://www.linkedin.com/company/time-n'%E2%80%8B-joy/">LinkedIn</a>
                <a href="https://twitter.com/TimeNjoy">Twitter</a>
                <a href="https://www.tiktok.com/@timenjoy_fr">Tiktok</a>
                <a href="https://www.pinterest.fr/timenjoy">Pinterest</a>
            </nav>
            <nav>
                <h4>A propos</h4>
                <a href="https://calendly.com/timenjoy/event?embed_domain=mytimenjoy.timenjoy.fr&embed_type=Inline" target="_blank" referrerPolicy='no-referrer'>Demandez une démo</a>
                <a href="mailto:sales-support@timenjoy.fr">Nous contacter</a>
                <Link to="/terms/cgv">CGV</Link>
                <Link to="/terms/cgu">CGU</Link>
                <Link to="/terms">Mentions Légales</Link>
            </nav>
        </PopupWrapper>
    )
}

export default Menu
import React from 'react'
import { ReactComponent as Arrow } from '../../../assets/arrow-input.svg'

const conditionalText = [
    `Timenjoy accompagne les acteurs du tourisme dans la valorisation de leur destination à travers l’offre évènementielle, culturelle et touristique de leur territoire.
    <br /><br />
    Une solution clé en main permettant aux institutionnels et acteurs du tourisme d’agréger l’ensemble du contenu évènementiel et touristique directement sur leurs solutions numériques.
    <br /><br />`,
    // `Timenjoy accompagne les organisateurs d’évènements dans l’optimisation de leur <b>communication évènementielle.</b>
    // <br /><br />
    // <b>Une technologie unique,</b> une approche intimiste et une image forte permettant de toucher le bon public, au bon endroit et au bon moment.
    // <br /><br />`,
    "Enrichir le contenu de votre supports numérique afin d’attirer plus de voyageurs.",
    "Informer les futures voyageurs de l’ensemble des évènements et activités touriste de votre destination.",
    "Collecter les données afin de mieux comprendre les habitudes et tendances des voyageurs",
    "Identifier et sélectionner le contenu pertinent à intégrer sur vos supports numériques.",
]
const Sect4 = () => {
    const [hovered, setHovered] = React.useState(0)
    return (
        <>
            <section className="sect-4">
                <div className="text">
                    <h2>Timenjoy Connect pour les professionnels du tourisme</h2>
                    <p dangerouslySetInnerHTML={{ __html: conditionalText[hovered] }} />
                </div>
                <div className="grid" onMouseLeave={() => setHovered(0)}>
                    <div className={hovered === 4 ? "active" : ""} onMouseEnter={() => setHovered(4)} onClick={() => setHovered(4)}>
                        <img src="/img/sect-4-grid-1.svg" alt="" />
                        <h6>Identifier</h6>
                    </div>
                    <div className={hovered === 1 ? "active" : ""} onMouseEnter={() => setHovered(1)} onClick={() => setHovered(1)}>
                        <img src="/img/sect-4-grid-2.svg" alt="" />
                        <h6>Enrichir</h6>
                    </div>
                    <div className={hovered === 2 ? "active" : ""} onMouseEnter={() => setHovered(2)} onClick={() => setHovered(2)}>
                        <img src="/img/sect-4-grid-3.svg" alt="" />
                        <h6>Diffuser</h6>
                    </div>
                    <div className={hovered === 3 ? "active" : ""} onMouseEnter={() => setHovered(3)} onClick={() => setHovered(3)}>
                        <img src="/img/sect-4-grid-4.svg" alt="" />
                        <h6>Collecter</h6>
                    </div>
                </div>
            </section>

            <section className="sect-4 sect-4-low">
                <h2>Timenjoy Connect pour les <br />professionnels du tourisme</h2>
                <p>
                    Timenjoy accompagne les acteurs du tourisme dans la valorisation de leur destination à travers l’offre évènementielle, culturelle et touristique de leur territoire.
                    <br />
                    Une solution clé en main permettant aux institutionnels et acteurs du tourisme d’agréger l’ensemble du contenu évènementiel et touristique directement sur leurs solutions numériques.
                </p>
                <div className="accordion">
                    <div className={hovered === 4 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 4 ? -1 : 4)}>
                            <img src="/img/sect-4-grid-1.svg" alt="" />
                            <h6>Identifier</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[4] }} />
                    </div>
                    <div className={hovered === 1 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 1 ? -1 : 1)}>
                            <img src="/img/sect-4-grid-2.svg" alt="" />
                            <h6>Enrichir</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[1] }} />
                    </div>
                    <div className={hovered === 2 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 2 ? -1 : 2)}>
                            <img src="/img/sect-4-grid-3.svg" alt="" />
                            <h6>Diffuser</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[2] }} />
                    </div>
                    <div className={hovered === 3 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 3 ? -1 : 3)}>
                            <img src="/img/sect-4-grid-4.svg" alt="" />
                            <h6>Collecter</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[3] }} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sect4

import React from 'react'
import { useMedia } from "@oriun/hookster"

const cardWidth = 380;
const cardCount = 5;

const Sect9 = () => {
    const [media, ref] = useMedia({
        '1': 'min-width:350px',
        '2': 'min-width:730px',
        '3': 'min-width:1010px',
        '4': 'min-width:1390px',
        '5': 'min-width:1770px'
    })
    const visible = parseInt(Object.keys(media).filter(a => media[a]).sort((a, b) => b - a)[0])
    const [index, setI] = React.useState(0);
    React.useEffect(() => {
        ref.current.style.transform = `translateX(calc( -${index} * ${cardWidth}px))`
    }, [index])
    return (
        <section className="sect-9">
            <div className="title">
                <h2>Ils nous font confiance</h2>
                <img src="/img/sect-9-icon.svg" alt="" />
            </div>
            <div className="row noscrollbar" ref={ref}>
                <div>
                    <img src="/img/ClaireBillard.png" alt="claire billard" />
                    <p>
                        Lors du Match France-Ecosse, nous avons eu l’occasion de sonder les personnes aux abords du stade quant à leur venue et Timenjoy est ressorti à de nombreuses reprises. Je suis ravie de reporter cette opération à nouveau pour nos matchs.
                    </p>
                    <span>- @FFR</span>
                </div>
                <div>
                    <img src="/img/alecia-vives.jpg" alt="alecia vives" />
                    <p>
                        Nous sommes ravis de travailler avec l'équipe de Time n' Joy depuis 2019. Véritable dispositif de référencement évènementiel, les outils proposés aux professionnels du secteur permettent de gagner en visibilité et de toucher de nouveaux prospects rapidement et sans prise de tête
                    </p>
                    <span>- @Groupe Barrière</span>
                </div>
                <div>
                    <img src="/img/HélèneParmentier.png" alt="helene parmentier" />
                    <p>
                        Solution pratique et intuitive, leur approche et leur manière de communiquer renforce l'image de marque des évènements, et j'ai pu mesurer les actions et les retombés de leur campagne directement grâce à leur statistiques pertinentes.
                    </p>
                    <span>- @ALLOVER</span>
                </div>
                <div>
                    <img src="/img/Mariana.jpg" alt="mariana dos santos" />
                    <p>
                        Nous travaillons depuis plusieurs années maintenant avec Timenjoy et nous remarquons une réelle différence sur la fréquentation de nos évènements. Il suffit d’une campagne pour que le Chapiteau la Belle de Mai soit affiché complet.
                    </p>
                    <span>- @Chapiteau la Belle de Mai</span>
                </div>
                <div>
                    <img src="/img/JulienGiarrizzi.png" alt="julien giarrizzi" />
                    <p>
                        Timenjoy est un mode de promotion et communication en plus pour communiquer sur mes évènements. C'est un plus indéniable qui permet d'être en relation encore plus étroite avec notre clientèle. Beaucoup de sérieux, de disponibilités et de réactivité !
                    </p>
                    <span>- @Vertigo</span>
                </div>
            </div>
            <div className="sub-row">
                <div className="pagination">
                    {new Array(cardCount).fill(1)
                        .map((_, i) => <div key={i} className={i >= index && i < (index + visible) ? "activated" : ""} />)}
                </div>
                <div className="arrows">
                    <img src={index > 0 ? "/img/sect-9-arrow-1.2.svg" : "/img/sect-9-arrow-1.svg"} className="previous" alt="" onClick={() => setI(a => Math.max(a - 1, 0))} />
                    <img src={index === (cardCount - visible) ? "/img/sect-9-arrow-2.2.svg" : "/img/sect-9-arrow-2.svg"} className="next" alt="" onClick={() => setI(a => Math.min(a + 1, cardCount - visible))} />
                </div>
            </div>
        </section>
    )
}

export default Sect9

import React from 'react'
// import { useHistory } from 'react-router-dom'
import Button from '../../Home_api/sections/button'

const Sect8 = () => {
    // const history = useHistory()
    return (
        <section className="sect-8">
            <h2>Les formats de diffusion<br /><span className="green">TIMENJOY</span></h2>
            <div className="arrows">
                <img src="/img/sect-8-arrow-1.png" alt="" />
                <img src="/img/sect-8-arrow-2.png" alt="" />
                <img src="/img/sect-8-arrow-3.png" alt="" />
            </div>
            <div className="columns">
                <h4>Solutions Timenjoy</h4>
                <h4>Réseaux partenaires</h4>
                <h4>Réseaux sociaux</h4>
                <span className="desktop">Site internet</span>
                <span className="mobile">Web</span>
                <span className="desktop">Office du tourisme</span>
                <span className="mobile">OT</span>
                <span>Facebook</span>
                <span>Chatbot</span>
                <span className="desktop">Site de réservation</span>
                <span className="mobile">Média</span>
                <span>Instagram</span>
                <span className="desktop">Application mobile</span>
                <span className="mobile">Apps</span>
                <span className="desktop">Média</span>
                <span className="mobile">Blogeur</span>
                <span>LinkedIn</span>
                <span>Blog</span>
                <span className="desktop">Agence de voyage</span>
                <span className="mobile">Influenceur</span>
                <span>Google</span>
                <span className="empty"></span>
                <span>Blogueur</span>
                <span>TikTok</span>
                <span className="empty desktop"></span>
                <span className="desktop">Influenceurs</span>
            </div>
            <img className="arrow" src="/img/sect-8-arrow-4.png" alt="" />
            <Button text="Prendre rendez-vous"/>
            {/* <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>Référencer mon évènement</a> */}
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
        </section>
    )
}

export default Sect8

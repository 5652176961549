import React from 'react'
import Button from '../../Home_api/sections/button'

const Sect3 = () => {
    return (
        <section className="sect-3">
            <div className="text">
                <h2>La technologie Timenjoy au service de vos évènements</h2>
                <p>
                Avec plus de 500 000 évènements référencés en France sur <a href="https://timenjoy.fr" style={{color: 'var(--green-enjoy)'}}>timenjoy.fr</a> et des milliers de visiteurs chaque semaine, nous savons comment adapter votre contenu et toucher le bon public, au bon endroit au bon moment. 
                <br />
                    <br />
                    <b>
                    Bénéficiez ainsi du conseil de nos experts et laissez-vous guider grâce à nos formules clés en main afin de vous concentrer sur les tâches à forte valeur ajoutée.
                    </b>
                </p>
                <div className="box">
                    <p>
                    Solution pratique et intuitive, leur approche et leur manière de communiquer renforce l'image de marque des évènements, et j'ai pu mesurer les actions et les retombées de leur campagne directement grâce à leurs statistiques pertinentes.
                    </p>
                    <div>
                        <img src="/img/Helene.jpg" alt="customer" />
                        <h4>Hélène</h4>
                        <h6>Les Plages Electroniques</h6>
                    </div>
                </div>
                <Button text="Découvrir les évènements" target="https://timenjoy.fr" anchor={false} />
            </div>
            <img src="/img/sect-3.png" alt="mytimenjoy" />
        </section>

    )
}

export default Sect3

import React from 'react'

const Sect2 = () => {
    return (
        <section className="sect-2">
            <div className="text">
                <h2>Bien plus<br />qu’un outil de<br />communication</h2>
                <p>
                    Timenjoy allie le meilleur de l’évènementiel et de la technologie permettant à des milliers d’utilisateurs chaque jour de découvrir leurs évènements favoris de façon personnalisée en fonction de leurs centres d’intérêts et localisation en temps réel sur un panel de solutions que nous avons quotidiennement dans notre poche.
                </p>
                <div className="list">
                    <img src="/img/sect-2-icon-1.svg" alt="" />
                    <h6>Référencez vos évènements</h6>
                    <img src="/img/sect-2-icon-2.svg" alt="" />
                    <h6>Diffusez vos évènements</h6>
                    <img src="/img/sect-2-icon-3.svg" alt="" />
                    <h6>Analysez vos performances</h6>
                </div>
            </div>
            <img src="/img/img-sect-2.svg" alt="mytimenjoy" />
        </section>

    )
}

export default Sect2

import React from 'react'
import { FaHandsHelping, FaMicrophone, FaFlag, FaAtom, FaUserFriends } from 'react-icons/fa'
import { MdPlace } from 'react-icons/md'

const Sect5 = () => {
    return (
        <section className="sect-5-studio">
            <img src="/img/sect-5-studio.png" alt="timenjoy" />
            <div className="text">
                <h2>Pourquoi Timenjoy&nbsp;?</h2>
                {/* <p>
                    Timenjoy, start-up dans l'évènementiel qui a été lancée en 2017, c'est...
                </p> */}
                <div className='list'>
                    <FaHandsHelping />
                    <h6>Une forte expertise dans le secteur de l’évènementiel</h6>
                    <FaMicrophone />
                    <h6>Pus de 500 000 évènements référencés chaque année</h6>
                    <FaFlag />
                    <h6>150 organisateurs qui nous font confiance</h6>
                    <FaUserFriends />
                    <h6>300 000 utilisateurs actifs à la recherche d’évènement</h6>
                    <MdPlace />
                    <h6>120 destinations en France</h6>
                    <FaAtom />
                    <h6>Des services orientés sur la conversion</h6>
                </div>
            </div>
        </section>

    )
}

export default Sect5

import React from 'react';
import Hero from './sections/hero'
import Sect2 from './sections/sect2'
import Sect3 from './sections/sect3'
import Sect4 from './sections/sect4'
import Sect5 from './sections/sect5'
import Sect6 from './sections/sect6'
import Sect7 from './sections/sect7'
import Sect8 from './sections/sect8'
import Sect9 from './sections/sect9'
import Sect10 from './sections/sect10'
import Sect11 from './sections/sect11'
import Sect12 from './sections/sect12'
import Sect15 from './sections/sect15'
import Sect16 from './sections/sect16'
import Header from './sections/header'
import './home.scss'

/**
 * It renders the header, the hero, the second section, the sixth section, the sixteenth section, the
 * ninth section, the fifteenth section, the tenth section, the eleventh section, and the twelfth
 * section
 * @returns A React component.
 */
const Home = () => {
    /* A hook that is used to change the style of the root element. */
    React.useEffect(() => {
        document.querySelector('#root').style = "height:unset;display:unset;overflow:unset;"
        return () => { document.querySelector('#root').style.height = "height:100vh;display:flex;overflow:hidden;" }
    }, [])
    return (
        <>
            <Header />
            <main>
                <Hero />
                <Sect2 />
                <Sect6 />
                <Sect16 />
                <Sect9 />
                <Sect15 />
                <Sect10 />
                <Sect11 />
                <Sect12 text={[
                    "Mytimenjoy permet aux professionnels de l’évènementiel de reférencer et les diffuser sur l’ensemble des solutions Timenjoy.",
                    "Chaque année, ce sont plus de 500 000 évènements référencés permettant de valoriser l’offre évènementielle des territoires.",
                    "Mytimenjoy est une innovation soutenue par la French Tech, BPI, le WEB Summit & les CES de Las VEGAS."
                ]} />
            </main>
        </>
    )
}


export default Home;
import React from 'react'

const Sect6 = () => {
    return (
        <section className="sect-6">
            <h2>Comment ça marche ?</h2>
            <div className="row">
                <div>
                    <img src="/img/block-4-6.svg" alt="" />
                    <div>
                        <h6>Créez-vous un compte Mytimenjoy</h6>
                        {/* <p>
                            Référencez gratuitement votre évènement sur l’ensemble de nos solutions et gagnez en visibilité.
                        </p> */}
                    </div>
                </div>
                <div>
                    <img src="/img/block-4-5.svg" alt="" />
                    <div>
                        <h6>Définissez votre forfait en fonction de vos évènements</h6>
                        {/* <p>Notre solution définit automatiquement l’audience la plus adapté en fonction de votre évènement et sa localisation</p> */}
                    </div>
                </div>
                <div>
                    <img src="/img/block-4-1.svg" alt="" />
                    <div>
                        <h6>Référencez votre évènement en quelques clics</h6>
                        {/* <p>Déinissez vos objectifs et Timenjoy s’occupe de diffuser votre évènements sur une multitude de supports pour convertir votre public</p> */}
                    </div>
                </div>
                <div>
                    <img src="/img/block-4-7.svg" alt="" />
                    <div>
                        <h6>Votre évènement sera diffusé immédiatement sur nos solutions et réseaux partenaire</h6>
                        {/* <p>
                            Analysez en temps réel les performances de votre campagnes sur votre tableau de bord. Mesurez le nombre de personnes atteintes,
                            le nombre de réservations, le taux de conversion et bien plus encore....
                        </p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Sect6

import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './button'
const Sect3 = () => {
    const history = useHistory()
    return (
        <>
            <section className="sect-3">
                <img src="/img/funnel.png" alt="mytimenjoy" />
                <div className="text">
                    <h2>La solution<br />Timenjoy Connect</h2>
                    <p>
                        Timenjoy centralise l’ensemble des données évènementielles et touristiques.<br /><br />
                        La solution épure l’ensemble des données, traite les doublons et garde uniquement les données qualitatives.<br /><br />
                        Timenjoy propose de nombreux filtres pour faciliter la recherche en vous permettant une meilleure granularité et sélection de la donnée qui vous intéresse.<br /><br />
                        Intégrer notre API et widget directement sur votre site internet pour diffuser l’offre de votre territoire en toute simplicité.
                    </p>
                    {/* <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>S'inscrire</a> */}
                    <Button/>
                </div>
            </section>
            {/* <section className="sect-3-funnel">
                <Button/>
                <div>
                    <img src="/img/funnel-4.png" alt=''/>
                    <p>Intégrez notre API et widget directement sur votre site internet pour diffuser l’offre de votre territoire en toute simplicité.</p>
                </div>
                <div>
                    <img src="/img/funnel-3.png" alt=''/>
                    <p>Timenjoy propose de nombreux filtres pour faciliter la recherche en vous permettant une meilleure granularité et sélection de la donnée qui vous intéresse.</p>
                </div>
                <div>
                    <img src="/img/funnel-2.png" alt=''/>
                    <p>La solution épure l’ensemble des données, traite les doublons et garde uniquement les données qualitatives.</p>
                </div>
                <div className>
                    <img src="/img/funnel-1.png" alt=''/>
                    <h2>La solution<br />Timenjoy Connect</h2>
                    <p>Timenjoy centralise l’ensemble des données évènementielles et touristiques.</p>
                </div>
            </section> */}
            <section className="vimeo-tnj">
                <h2>Timenjoy Connect en vidéo</h2>
                <div style={{ position: 'relative' }}>
                    <iframe src="https://player.vimeo.com/video/579756063?h=2f73f3e73c&color=b3cf42&title=0&byline=0&portrait=0" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
            </section>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </>
    )
}

export default Sect3

import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './button'
const Sect5 = () => {
    const history = useHistory()
    function scroll() {
        document.querySelector('#form').scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <section className="sect-5">
            <h2>Choisissez parmi les deux types d'intégration :</h2>
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
            <div className="normal">
                <div onClick={scroll}>
                    <h4>Freemium</h4>
                    <ul>
                        <li>Intégration gratuite de nos widgets sur vos solutions en co-branding Timenjoy.</li>
                        <li>Trois formats d’intégration disponible.</li>
                        <li>Accès à l’ensemble des catégories, destinations et filtres disponibles.</li>
                        <li>Personnalisation de nos widgets en cohérence avec votre charte graphique.</li>
                        <li>Lien vers Timenjoy obligatoire.</li>
                    </ul>
                </div>
                <div onClick={scroll}>
                    <h4>Premium</h4>
                    <ul>
                        <li>Accéder directement à notre API.</li>
                        <li>Marque blanche.</li>
                        <li>Aucune redirection sur le site Timenjoy.</li>
                        <li>Accès complèt à la base de données dans votre zone de géolocalisation.</li>
                        <li>Support technique dédié</li>
                    </ul>
                </div>
            </div>
            <Button/>
            {/* <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>S'inscrire</a> */}
        </section>

    )
}

export default Sect5

import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import Popup from '../../../components/Popup/Popup'
import Burger from '../../../components/UI/Burger/burger'
import Button from '../../Home_api/sections/button'
import Menu from './menu'

const Header = ({ fixed }) => {
    const history = useHistory()
    const [popup, setPopup] = React.useState()
    const [hamburgerState, setH] = React.useState(false)
    return (
        <>
            {popup && <Popup history={history} setPopup={setPopup} setValue={console.log} />}
            <Menu open={hamburgerState} close={() => setH(false)} className="mobile-menu" />
            <header className={fixed ? "fixed" : ""}>
                <img src="/img/logo.webp" alt="timenjoy mytimenjoy logo" className="mobile" />
                <nav className='landing'>
                    <img src="/img/logo.webp" alt="timenjoy mytimenjoy logo" className="desktop" />

                    <Link to="/home" className={["/home", "/"].includes(history.location.pathname) ? "current" : ""}>Mytimenjoy</Link>
                    <Link to="/performance" className={history.location.pathname === "/performance" ? "current" : ""}><span>Timenjoy</span>&nbsp;Performance</Link>
                    <Link to="/studio" className={history.location.pathname === "/studio" ? "current" : ""}><span>Timenjoy</span>&nbsp;Studio</Link>
                    {/* <Link to="/connect" className={history.location.pathname === "/connect" ? "current" : ""}><span>Timenjoy</span>&nbsp;Connect</Link> */}
                </nav>
                {["/home", "/"].includes(history.location.pathname) ? <nav>
                    <a href="#" onClick={e => { e.preventDefault(); setPopup(true) }}>Se connecter</a>
                    <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>S'inscrire</a>
                </nav>
                    :
                    <nav>
                        <Button text="Prendre rendez-vous" />
                    </nav>}
                <Burger className="mobile" status={hamburgerState} onClick={() => setH(a => !a)} fixed /*fixed={fixed} animated={!!fixed}*/ fixedPos={{ right: 'var(--margin)', top: 32 }} />
            </header>
        </>
    )
}

export default Header

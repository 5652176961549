import React from 'react'
import './burger.scss'

/**
 * It renders a hamburger icon that can be clicked to open a menu
 * @returns A hamburger menu
 */
const Burger = ({ status = false, onClick, className = "", fixed = false, animated = true, fixedPos }) => {
    const [position, setP] = React.useState()
    const ref = React.useRef(null)
    /* Setting the position of the hamburger menu when it is open. */
    React.useEffect(() => {
        if (!status) setP(null)
        else if (ref.current) {
            const { x, y } = ref.current.getBoundingClientRect()
            console.log(x, y, fixed)
            fixed && setP(fixedPos || { left: x, top: y })
        }
    }, [status])
    /* Returning a hamburger menu. */
    return (
        <>
            {position && <div className='hamburger'/* Placeholder */ />}
            <div
                ref={ref}
                style={position ? { ...position, position: 'fixed', zIndex: 1e10 } : {}}
                className={"hamburger " + className + ((status && animated) ? " open" : " closed")}
                onClick={onClick}
            >
                <div />
                <div />
                <div />
            </div>
        </>
    )
}

export default Burger
import React, { Suspense, lazy, useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import { Provider, useMainContext } from "./contexts/main";
import Join from "./views/Register/join";
import DesignGuidelines from "./components/Guidelines";
import Home from "./views/Home/home";
import HomeAPI from "./views/Home_api/home";
import HomeStudio from "./views/Home_studio/home";
import HomePerf from "./views/Home_perf/home";
import { Provider as FeedbackProvider } from "@oriun/use-feedback";

const Reset = lazy(() => import("./views/Reset"));
const Main = lazy(() => import("./views/Main/main"));
// const Home = lazy(() => import('./views/Home/home'))
// const HomeAPI = lazy(() => import('./views/Home_api/home'))
// const HomeStudio = lazy(() => import('./views/Home_studio/home'))
// const HomePerf = lazy(() => import('./views/Home_perf/home'))
const Register = lazy(() => import("./views/Register/register"));
const FirstPage = lazy(() => import("./views/ApiPath/firstPage"));
const CGU = lazy(() => import("./views/Legal/cgu"));
const Mentions = lazy(() => import("./views/Legal/mentions"));

/**
 * If the user is not authenticated, redirect them to the login page. Otherwise, render the component
 * @returns A Route component with a Suspense component as a child.
 */
const SuspenseRoute = (props) => {
  if (props.auth && !props.auth()) return <Redirect to="/login" />;
  let { fallback, component: Component } = props;
  let newProps = { ...props };
  delete newProps.fallback;
  delete newProps.component;
  return (
    <Route {...newProps}>
      <Suspense
        fallback={
          fallback || (
            <div
              style={{
                height: "100%",
                width: "100%",
                zIndex: 1000,
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "white"
              }}
            ></div>
          )
        }
      >
        <Component {...newProps} />
      </Suspense>
    </Route>
  );
};

/**
 * It listens for changes in the history object, and when it detects a change, it scrolls to the top of
 * the page
 * @returns An empty component
 */
const ScrollToTopOnPageChange = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    return () => {
      unlisten();
    };
  }, []);
  return <></>;
};

/**
 * It renders the DesignGuidelines component, then a Switch component that renders the Main component
 * if the user is logged in, the Register component if the path is /register, the Join component if the
 * path is /join/:id, the FirstPage component if the path is /details*, the Reset component if the path
 * is /user/forgot_password/:session, the CGU component if the path is /terms/cgu, the Mentions
 * component if the path is /terms, the HomeAPI component if the path is /connect or /api, the
 * HomeStudio component if the path is /studio, the HomePerf component if the path is /performance, and
 * the Home component if the path is /, /home, /login, or * (which means any other path)
 * @returns A router that will render the different pages depending on the url.
 */
const Router = () => {
  const [context] = useMainContext();
  const logged = !!(context.user && sessionStorage.token);

  return (
    <FeedbackProvider>
      <BrowserRouter>
        <ScrollToTopOnPageChange />
        <DesignGuidelines />
        <Switch>
          <SuspenseRoute path="/app" component={Main} auth={() => logged} />
          <SuspenseRoute exact path="/register" component={Register} />
          <SuspenseRoute exact path="/join/:id" component={Join} />
          <SuspenseRoute path="/details*" component={FirstPage} />
          <SuspenseRoute
            exact
            path="/user/forgot_password/:session"
            component={Reset}
          />
          <SuspenseRoute exact path="/terms/cgu" component={CGU} />
          <SuspenseRoute exact path="/terms" component={Mentions} />
          <SuspenseRoute
            exact
            path={["/connect", "/api"]}
            component={HomeAPI}
          />
          <SuspenseRoute exact path="/studio" component={HomeStudio} />
          <SuspenseRoute exact path="/performance" component={HomePerf} />
          <SuspenseRoute exact path={["/", "/home"]} component={Home} />
          <Route path={["/login", "*"]}>
            <Redirect to="/" />
          </Route>
        </Switch>
      </BrowserRouter>
    </FeedbackProvider>
  );
};

/**
 * We're creating a meta tag with the name "facebook-domain-verification" and the content
 * "xoahhzp4yiu43j9uy2pc5jc2m5hbmj" and appending it to the head of the document
 * @returns A React component that renders the Router component and a div with the id of popup-root.
 */
const App = () => {
  /* It's creating a meta tag with the name "facebook-domain-verification" and the content
    "xoahhzp4yiu43j9uy2pc5jc2m5hbmj" and appending it to the head of the document. */
  useEffect(() => {
    let meta = document.createElement("meta");
    meta.name = "facebook-domain-verification";
    meta.content = "xoahhzp4yiu43j9uy2pc5jc2m5hbmj";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }, []);

  /* It's rendering the Router component and a div with the id of popup-root. */
  return (
    <Provider>
      <Router />
      <div id="popup-root" />
    </Provider>
  );
};

export default App;

import React from 'react'
import Button from '../../Home_api/sections/button'

const Sect5 = () => {
    return (
        <section className="sect-5 the-hard-one">
            {/* <h2>Construire une stratégie<br />multicanale efficace</h2> */}
            <h2>Construire une stratégie de <br />communication performante et efficace</h2>
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
            <div className="zarbi">
                <div>
                    <img src="/img/sect-5-icon-1.svg" alt="" />
                    <h4>Maximisez la visibilité<br />de vos évènements</h4>
                    <p>Mettez en avant vos évènements sur les solutions Timenjoy, nos Réseaux Sociaux et auprès de notre réseau partenaire.</p>
                </div>
                <div>
                    <img src="/img/sect-5-icon-2.svg" alt="" />
                    <h4>Générez du traffic qualifié</h4>
                    <p>Incitez vos prospects à interagir avec votre évènement et redirigez du trafic qualifié sur votre évènement.</p>
                </div>
                <div>
                    <img src="/img/sect-5-icon-3.svg" alt="" />
                    <h4>Transformez les<br />visiteurs en participants</h4>
                    <p>Multipliez les prises de contact et segmentez vos visiteurs selon leurs niveaux d'engagement. Augmentez votre conversion.</p>
                </div>
                <div>
                    <img src="/img/sect-5-icon-4.svg" alt="" />
                    <h4>Augmentez votre<br />taux de fidélisation</h4>
                    <p>Segmentez les audiences selon leur participations à vos évènements et récoltez les retours clients afin de créer de campagnes personalisées.</p>
                </div>
                <img src="/img/sect-5-arrow-1.png" alt="" className="arrow" />
                <img src="/img/sect-5-arrow-2.png" alt="" className="arrow" />
                <img src="/img/sect-5-arrow-3.png" alt="" className="arrow" />
            </div>
            <Button text="Prendre rendez-vous"/>
        </section>

    )
}

export default Sect5

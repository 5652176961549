import React from 'react'
import { useHistory } from 'react-router-dom'

const pricings = [
    { name: "freemium", price: 0, advantages: ["Accès à MyTimenjoy", "Gestion des évènements", "Référencement évènement (1 évènement par mois)"] },
    { name: "basique", price: 12.99, advantages: ["Accès à MyTimenjoy", "Gestion des évènements", "Référencement évènement (jusqu'à 5 par mois)"] },
    { name: "premium", price: 24.99, advantages: ["Accès à MyTimenjoy", "Gestion des évènements", "Référencement évènement (illimité)", "Accès aux statistiques", "Diffusion Réseaux Partenaires", "Widgets"] },
]

const PricingBlock = ({ name, price, advantages, button = "Inscription" }) => {
    const history = useHistory()
    return <div className='pricing-block'>
        <div className='top' />
        <h6>{name}</h6>
        <h3>{price}<sup>€</sup></h3>
        <span>par mois</span>
        <ul>
            {advantages.map(a => <li>{a}</li>)}
        </ul>
        <div className='filler' />
        <button onClick={e => { e.preventDefault(); history.push('/register') }}>{button}</button>
    </div>
}
const Sect15 = () => {
    return <section className='sect-15'>
        <img src="/img/pricing.png" alt="" />
        <div className='text'>
            <h2>Nos offres</h2>
            <h4>
                {/*  */}
            </h4>
            <div className="pricings">
                {pricings.map(price => <PricingBlock {...price} />)}
            </div>
        </div>
    </section>
}

export default Sect15
import React from 'react'
import './roundedCheckBox.scss'

/**
 * `RoundedCheckBox` is a function that returns a `div` with a `div` inside of it that has a class of
 * `on` or `off` depending on the value of the `value` prop
 * @returns A component that renders a checkbox.
 */
const RoundedCheckBox = ({ value, onChange, className }) => {
    const [val, setV] = React.useState(false)
   /* Calling the `onChange` function with the value of `val` as the argument. */
    React.useEffect(() => {
        let _ = onChange?.(val)
    }, [val])
    return (
        <div className={"rounded-check-box " + (className ?? '')} onClick={() =>setV(!(value || val))}>
            <div className={(value || val) ? 'on' : 'off'}></div>
        </div>
    )
}

export default RoundedCheckBox

import { AES } from "crypto-js";
import { POST } from '../helpers/fetch'
import { APISAILS_URL } from "./Config"

/* Creating an object with the appId, password, and expiredDate. */
const obj = {
    appId: process.env.REACT_APP_API_KEY,
    password: process.env.REACT_APP_API_PASS,
    expiredDate: new Date(Date.now() + 11800000).toISOString(),
};
/* Encrypting the object with the secret key. */
const encrypted = AES.encrypt(JSON.stringify(obj), process.env.REACT_APP_ENCRYPT_SECRET).toString();
/* Creating a data object with the appId and password. */
const data = {
    appId: encodeURIComponent(encrypted),
    password: "35b7009ec0"
}

/**
 * > If the login fails, wait one second and try again
 * @returns A promise that resolves to a token
 */
function auth() {
    return POST(APISAILS_URL + "/auth/login", data)
        .then(r => {
            sessionStorage.setItem('token', r.token)
            return r.token
        })
        .catch(err => {
            console.error(err)
            return new Promise(r => setTimeout(() => auth().then(r), 1000))
        })
}
export const authPromise = auth()


import React from 'react'
import Button from '../../Home_api/sections/button'
import Header from './header'

const Hero = () => {
    const ref = React.useRef(null)
    const [fixedHeader, setF] = React.useState(false)
    React.useEffect(() => {
        new IntersectionObserver(
            ([entry]) => console.log('entry', entry) || setF(!Math.floor(entry.intersectionRatio)),
            { threshold: 1 }
        ).observe(ref.current)
    }, [])
    return (
        <>
            {fixedHeader && <Header fixed />}
            <section className="hero">
                <div className="text" ref={ref}>
                    <h1>L'agence de communication 360 pour vos évènements</h1>
                    <h3>Notre mission est de faire vibrer le cœur de vos participants</h3>
                    <h5>
                        À travers à un écosystème complet  et une expertise dans le secteur de l’évènementiel, Timenjoy studio regroupe l'ensemble des services d’une agence digitale. Nous aidons les organisateurs d’évènements à propulser leurs évènements à un autre niveau.
                    </h5>
                    <Button text="Découvrir" target="#services" />
                </div>
                <img src="/img/studio-hero.png" alt="mytimenjoy" />
            </section>
            <section className="customer-head">
                <h2>Plus de 150 organisateurs font confiance à Timenjoy</h2>
            </section>
            <section className="customer noscrollbar">
                <img src="/img/partners/nice-jazz.png" alt="timenjoy partner" />
                <img src="/img/partners/cabaret.png" alt="timenjoy partner" />
                <img src="/img/partners/metropole.png" alt="timenjoy partner" />
                <img src="/img/partners/sud-concert.png" alt="timenjoy partner" />
                <img src="/img/partners/crossover.png" alt="timenjoy partner" />
                <img src="/img/partners/chapiteau.png" alt="timenjoy partner" />
                <img src="/img/partners/sharks.png" alt="timenjoy partner" />
                <img src="/img/partners/directo.png" alt="timenjoy partner" />
                <img src="/img/partners/ffr.png" alt="timenjoy partner" />
                <img src="/img/partners/fff.png" alt="timenjoy partner" />
            </section>
        </>
    )
}

export default Hero

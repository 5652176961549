import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './button'

const Sect3 = () => {
    const history = useHistory()
    return (
        <section className="sect-3 sect-16">
            <img src="/img/sect-16.png" alt="mytimenjoy" />
            <div className="text">
                <h2>Des solutions incontournables pour vos évènements</h2>
                <p>
                Maximiser la présence de vos évènements en les diffusant sur l’ensemble de nos solutions. Chaque jour ce sont des milliers d’utilisateurs qui parcourent les solutions Timenjoy à la recherche d’évènements et d’expériences dans plus de 150 destinations en France et en Europe. Grâce à sa technologie unique, Timenjoy recommande vos évènements en fonction des centres d’intérêt et de la localisation des visiteurs.
                </p>
                <Button target="https://timenjoy.fr" anchor={false} text="Découvrir les évènements"/>
            </div>
        </section>
    )
}

export default Sect3

import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from './button'
const Sect10 = () => {
    const history = useHistory()
    return (
        <section className="sect-10">
            <img src="/img/img-sect-10.png" alt="" />
            <div className="text">
                <h2>“ Intégrer l'offre évènementielle et touristique de son territoire n'a jamais été aussi simple avec Timenjoy. ”</h2>
                <Button/>
            </div>
        </section>
    )
}

export default Sect10

/**
 * "It returns a function that takes a url, body, headers, and jsonBody, and returns a fetch request
 * with the given method, headers, and body."
 * 
 * The function is a higher order function because it returns a function
 */
const RequestWithBody = method => (
    url,
    body = {},
    headers = { 'Content-Type': 'application/json' },
    jsonBody = true
) => fetch(url, {
    method: method,
    headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
    body: jsonBody ? JSON.stringify(body) : body
})
    .then(response => response.json())

/* Exporting the functions that are returned from the higher order function. */
export const POST = RequestWithBody('POST')
export const PUT = RequestWithBody('PUT')
export const PATCH = RequestWithBody('PATCH')
export const DELETE = RequestWithBody('DELETE')

// export const DELETE = (url, headers = {}) => fetch(url, {
//     method: "DELETE",
//     headers: Object.assign({ 'Content-Type': 'application/json' }, headers)
// })
//     .then(response => response.json())

/**
 * It takes a URL and an optional object of headers, and returns a promise that resolves to the JSON
 * response
 * @param url - The URL to make the request to.
 * @param [headers] - Object.assign({ 'Content-Type': 'application/json' }, headers)
 */
export const GET = (url, headers = {}) => fetch(url, {
    headers: Object.assign({ 'Content-Type': 'application/json' }, headers)
})
    .then(response => response.json())


import React from 'react'
import { useMedia } from "@oriun/hookster"


const Sect11 = () => {
    const [media, ref] = useMedia({ 'twoColumn': 'min-width:861px' })
    const [index, setI] = React.useState(0);
    React.useEffect(() => {
        if (!ref.current) return
        [...ref.current.children].forEach(a => a.style.transform = `translateX(calc( -${index} * (100% + 30px) ))`)
    }, [index])
    return (
        <section className="sect-11">
            <h2>Ils en parlent</h2>
            <div className="grid-row noscrollbar" ref={ref}>
                <div>
                    <img src="/img/le-monde.png" alt="" />
                    <div>
                        <h4>“ Si les classiques Yelp et Trip Advisor peuvent être bien utiles pour sortir boire un verre, il faut davantage se tourner vers Time N’joy lorsqu’on chercher des conseils précis ”</h4>
                        <span>
                            {/* <img src="/img/sect-11-icon.svg" alt="" /> */}
                            Le Monde
                        </span>
                    </div>
                </div>
                <div>
                    <img src="/img/nice-matin.png" alt="" />
                    <div>
                        <h4>“ Pour les organisateurs d’évènements, la start-up est un véritable outil stratégique fr communication pour mettre en avant leur travail  ”</h4>
                        <span>
                            Nice Matin
                        </span>
                    </div>
                </div>
                <div>
                    <img src="/img/la-provence.jpg" alt="" />
                    <div>
                        <h4>“ Timenjoy veut devenir la bible de vos sorties ”</h4>
                        <span>
                            La Provence
                        </span>
                    </div>
                </div>
                <div>
                    <img src="/img/la-tribune.png" alt="" />
                    <div>
                        <h4>“ Timenjoy permet de faire connaître un évènement plus original, moins connu, se déroulant dans d’autres cités que les grandes villes. ”</h4>
                        <span>
                            La Tribune
                        </span>
                    </div>
                </div>
            </div>
            <div className="sub-row">
                <div className="pagination">
                    <div className={index === 0 ? "activated" : ""} />
                    <div className={(index === 0 && media.twoColumn) || index === 1 ? "activated" : ""} />
                    <div className={index === 2 ? "activated" : ""} />
                    <div className={(index === 2 && media.twoColumn) || index === 3 ? "activated" : ""} />
                </div>
                <div className="arrows">
                    <img src={index > 0 ? "/img/sect-9-arrow-1.2.svg" : "/img/sect-9-arrow-1.svg"} className="previous" alt="" onClick={() => setI(a => Math.max(a - (1 + media.twoColumn), 0))} />
                    <img src={index === (4 - media.twoColumn) ? "/img/sect-9-arrow-2.2.svg" : "/img/sect-9-arrow-2.svg"} className="next" alt="" onClick={() => setI(a => Math.min(a + (1 + media.twoColumn), 4 - media.twoColumn))} />
                </div>
            </div>
        </section>
    )
}

export default Sect11

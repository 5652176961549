import React from 'react'
import { useHistory } from 'react-router-dom'
import Header from './header'

const Hero = () => {
    const history = useHistory()
    const ref = React.useRef(null)
    const [fixedHeader, setF] = React.useState(false)
    React.useEffect(() => {
        new IntersectionObserver(
            ([entry]) => console.log('entry', entry) || setF(!Math.ceil(entry.intersectionRatio)),
            { threshold: 0 }
        ).observe(ref.current)
    }, [])
    return (
        <>
            {fixedHeader && <Header fixed />}
            <section className="hero">
                <div className="text" ref={ref}>
                    <h1>Référencez<br />votre évènement</h1>
                    <h3>Afin de les diffuser à des milliers<br/>d’utilisateurs chaque jours.</h3>
                    <h5>Avec plus de 500 000 évènements référencés chaque année sur nos solutions, Timenjoy est aujourd’hui utilisé par des milliers de personnes chaque jour à la recherche d’évènements. Référencez vos évènements en quelques clics seulement et diffusez-les sur l’ensemble de nos solutions et réseaux partenaires. Rapide, simple et efficace.</h5>
                    <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>Référencer mon évènement</a>
                </div>
                <img src="/img/img-sect-1@3x.png" alt="mytimenjoy" />
            </section>
            <section className="customer-head">
                <h2>Plus de 500 000 évènements référencés, pourquoi pas vous ?</h2>
            </section>
            <section className="customer noscrollbar">
                <img src="/img/partners/nice-jazz.png" alt="timenjoy partner" />
                <img src="/img/partners/cabaret.png" alt="timenjoy partner" />
                <img src="/img/partners/metropole.png" alt="timenjoy partner" />
                <img src="/img/partners/sud-concert.png" alt="timenjoy partner" />
                <img src="/img/partners/crossover.png" alt="timenjoy partner" />
                <img src="/img/partners/chapiteau.png" alt="timenjoy partner" />
                <img src="/img/partners/sharks.png" alt="timenjoy partner" />
                <img src="/img/partners/directo.png" alt="timenjoy partner" />
                <img src="/img/partners/ffr.png" alt="timenjoy partner" />
                <img src="/img/partners/fff.png" alt="timenjoy partner" />
            </section>

        </>
    )
}

export default Hero

import React from 'react'
import { ReactComponent as Arrow } from '../../../assets/arrow-input.svg'

const conditionalText = [
    `Timenjoy accompagne les organisateurs d’évènements dans l’optimisation de leur <b>communication évènementielle.</b>
    <br /><br />
    <b>Une technologie unique,</b> une approche intimiste et une image forte permettant de toucher le bon public, au bon endroit et au bon moment.
    <br /><br />`,

    "En fonction des centres d’intérêt et comportement de votre audience, Timenjoy vous accompagne à définir le bon message afin d’optimiser votre taux de conversion et augmenter vos ventes.",

    "Analysez en temps réel les performances de vos campagnes grâce à des tableaux de bords sur mesure. Mesurez le nombre de personnes atteintes, leurs données démographiques et votre taux de conversion.",

    "Définissez vous-même votre budget en fonction de vos objectifs et de la taille de votre audience. Payez uniquement en fonction de vos résultats.",

    "En référençant votre évènement, notre algorithme identifie automatiquement votre audience cible en fonction de la catégorie et localisation de votre évènement.",
]
const Sect4 = () => {
    const [hovered, setHovered] = React.useState(0)
    return (
        <>
            <section className="sect-4">
                <div className="text">
                    <h2>Timenjoy pour les<br />professionnels de<br />l’évènementiel</h2>
                    <p dangerouslySetInnerHTML={{ __html: conditionalText[hovered] }} />
                </div>
                <div className="grid" onMouseLeave={() => setHovered(0)}>
                    <div className={hovered === 4 ? "active" : ""} onMouseEnter={() => setHovered(4)} onClick={() => setHovered(4)}>
                        <img src="/img/sect-4-grid-1.svg" alt="" />
                        <h6>Ciblez votre <br />audience</h6>
                    </div>
                    <div className={hovered === 1 ? "active" : ""} onMouseEnter={() => setHovered(1)} onClick={() => setHovered(1)}>
                        <img src="/img/sect-4-grid-2.svg" alt="" />
                        <h6>Personnalisez <br />vos messages</h6>
                    </div>
                    <div className={hovered === 2 ? "active" : ""} onMouseEnter={() => setHovered(2)} onClick={() => setHovered(2)}>
                        <img src="/img/sect-4-grid-3.svg" alt="" />
                        <h6>Analysez vos <br />performances</h6>
                    </div>
                    <div className={hovered === 3 ? "active" : ""} onMouseEnter={() => setHovered(3)} onClick={() => setHovered(3)}>
                        <img src="/img/sect-4-grid-4.svg" alt="" />
                        <h6>Optimisez votre <br />budget</h6>
                    </div>
                </div>
            </section>

            <section className="sect-4 sect-4-low">
                <h2>Time N’ Joy pour les <br />professionnels de l’évènementiel</h2>
                <p>
                    Timenjoy accompagne les organisateurs d’évènements dans l’optimisation de leur <b>communication évènementielle.</b>
                    <br />
                    <b>Une technologie unique,</b> une approche intimiste et une image forte permettant de toucher le bon public, au bon endroit et au bon moment.
                </p>
                <div className="accordion">
                    <div className={hovered === 4 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 4 ? -1 : 4)}>
                            <img src="/img/sect-4-grid-1.svg" alt="" />
                            <h6>Ciblez votre audience</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[4] }} />
                    </div>
                    <div className={hovered === 1 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 1 ? -1 : 1)}>
                            <img src="/img/sect-4-grid-2.svg" alt="" />
                            <h6>Personnalisez vos messages</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[1] }} />
                    </div>
                    <div className={hovered === 2 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 2 ? -1 : 2)}>
                            <img src="/img/sect-4-grid-3.svg" alt="" />
                            <h6>Analysez vos performances</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[2] }} />
                    </div>
                    <div className={hovered === 3 ? "active" : ""}>
                        <span onClick={() => setHovered(a => a === 3 ? -1 : 3)}>
                            <img src="/img/sect-4-grid-4.svg" alt="" />
                            <h6>Optimisez votre budget</h6>
                            <div className="filler" /><Arrow />
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: '<br/>' + conditionalText[3] }} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sect4

import React from 'react'

const Button = ({ text, className, target = "#calendly", anchor = true }) => {
    function scroll() {
        document.querySelector(target).scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <a className={"demo " + (className || "")} href={target} onClick={e => { if (anchor) { e.preventDefault(); scroll() } }}>{text || 'Demander une démo'}</a>
    )
}

export default Button

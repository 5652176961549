const prod = {
  APISAILS_URL: "https://apisails.timenjoy.fr",
  WEBSITE_URL: "https://timenjoy.fr",
  CRAWLOSAURE_API: "https://crawlosaure.api.timenjoy.fr",
  STRIPE_API: "https://stripe-server.timenjoy.fr",
  MTNJ_URL: "https://mytimenjoy.timenjoy.fr",
  APIRATE_URL: "https://apirate.timenjoy.fr"
};

const devt = {
  APISAILS_URL: "https://apisails-dev.timenjoy.fr",
  WEBSITE_URL: "https://timenjoy-dev.timenjoy.fr",
  CRAWLOSAURE_API: "https://crawlosaure-dev.api.timenjoy.fr",
  STRIPE_API: "https://stripe-server-dev.timenjoy.fr",
  MTNJ_URL: "https://mytimenjoy-dev.timenjoy.fr",
  APIRATE_URL: "https://apirate-dev.timenjoy.fr"
};

const local = {
  APISAILS_URL: "http://localhost:1337",
  WEBSITE_URL: "http://localhost:4300",
  CRAWLOSAURE_API: "http://localhost:8787",
  APIRATE_URL: "http://localhost:5000" // Change port to suit your needs
};

const config = {
  debut: process.env.REACT_APP_MODE !== "production",
  ...(process.env.REACT_APP_MODE === "local"
    ? local
    : process.env.REACT_APP_MODE === "development"
    ? devt
    : prod)
};

export default config;

export const WEBSITE_URL = config.WEBSITE_URL;
export const APISAILS_URL = config.APISAILS_URL;
export const CRAWLOSAURE_API = config.CRAWLOSAURE_API;
export const STRIPE_API = config.STRIPE_API;
export const APIRATE_URL = config.APIRATE_URL;
export const MTNJ_URL = config.MTNJ_URL;

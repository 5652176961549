import React, { createContext, useContext, useEffect } from 'react'
import { useDiffState } from '@oriun/hookster';

/* It's creating a React Context */
const MainContext = createContext({})

/**
 * It's a React Context Provider that uses the `useDiffState` hook to store state in sessionStorage and
 * log it to the console
 * @returns A React Context Provider
 */
export const Provider = ({ children }) => {
    /* It's using the `useDiffState` hook to store state in sessionStorage and log it to the console */
    const [state, setState] = useDiffState(JSON.parse(sessionStorage.getItem("context") || "{}"))
    /* It's storing the state in sessionStorage and creating a global function to log the state to the
    console */
    useEffect(() => {
        sessionStorage.setItem("context", JSON.stringify(state))
        window.logContext = (label = 'logcontext') => console.log(label, state)
    }, [state])
    return <MainContext.Provider value={{ state, setState }}>{children}</MainContext.Provider>
}
/**
 * It returns a tuple of the state and the setState function from the MainContext
 * @returns The state and the setState function
 */
export const useMainContext = () => {
    const context = useContext(MainContext)
    return [context.state, context.setState]
}

export default MainContext
import React from 'react'

const Sect2 = () => {
    return (
        <section className="sect-2">
            <div className="text">
                <h2>Connectez-vous à l'offre évènementielle, culturelle et touristique de votre territoire</h2>
                <p>
                    Une solution digitale clé en main permettant aux acteurs du tourisme d’enrichir leur contenu et diffuser l’ensemble de l’offre évènementielles et touristiques de leurs territoires. 
                </p>
                <div className="list">
                    <img src="/img/sect-2-icon-1.svg" alt="" />
                    <h6>Plus de 150 destinations</h6>
                    <img src="/img/sect-2-icon-2.svg" alt="" />
                    <h6>Plus de 18 grandes catégories</h6>
                    <img src="/img/sect-2-icon-3.svg" alt="" />
                    <h6>De nombreux filtres disponibles</h6>
                </div>
            </div>
            <img src="/img/img-sect-2.svg" alt="mytimenjoy" />
        </section>

    )
}

export default Sect2

import React, { useEffect } from 'react'

const Sect6 = () => {
    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://assets.calendly.com/assets/external/widget.js"
        script.type = "text/javascript"
        script.async = true
        document.body.appendChild(script)
    })
    return (
        <section className="sect-13" id="calendly">
            <h3>
            Vous souhaitez en savoir plus? Prenez rendez-vous dès maintenant avec l’un de nos experts.
            </h3>
            <div class="calendly-inline-widget" data-url="https://calendly.com/timenjoy/timenjoy-studio?hide_gdpr_banner=1&primary_color=bfd241&embed_domain=mytimenjoy.timenjoy.fr&embed_type=Inline" style={{ minWidth: "320px", height: "90vh" }}></div>
            {/* <div class="calendly-inline-widget" data-url="https://calendly.com/d/cd9-9yr-c3z/timenjoy-connect-demo?embed_domain=mytimenjoy.timenjoy.fr&embed_type=Inline" style={{ minWidth: "320px", height: "90vh" }}></div> */}
        </section>
    )
}

export default Sect6
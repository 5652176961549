import React from 'react'
import { FaCheckCircle, FaCamera, FaPhotoVideo, FaHeart } from 'react-icons/fa'
import Button from '../../Home_api/sections/button'

const Sect2 = () => {
    return (
        <section className="sect-2">
            <img src="/img/sect-2.png" alt="mytimenjoy" />
            <div className="text">
                <h2>Libérez le potentiel de vos évènements avec Timenjoy Studio</h2>
                <p>
                Après plusieurs années à analyser  les codes de la communication évènementielle, Timenjoy lance Timenjoy studio : l’agence qui aide les professionnels de l’évènementiel à communiquer avant, pendant et après le jour J.
                </p>
                <div className="list">
                    <FaCheckCircle />
                    <h6>Valorisez votre image de marque</h6>
                    <FaCamera />
                    <h6>Créer du contenu pertinent</h6>
                    <FaPhotoVideo />
                    <h6>Toucher votre public</h6>
                    <FaHeart />
                    <h6>Fidéliser vos participants</h6>
                </div>
                <Button text="Prendre rendez-vous" target="#calendly" />
            </div>
            <img src="/img/grid-pattern.svg" alt="" className="grid-pattern" />
        </section>

    )
}

export default Sect2

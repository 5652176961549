import React from 'react'
import './Errors.scss'

/**
 * It takes in a message and a type, and returns a div with a background color of green if the type is
 * true, and red if the type is false
 * @returns A div with a class of error-box and a h1 with the message passed in.
 */
const Errors = ({message, type}) => {

    return (
        <>
            <div className="error-box" style={{background: type ? "#b3cf42" : "#F40009"}}>
                <h1>{message}</h1>
            </div>
        </>
    )
}

export default Errors
import React, { useEffect } from 'react'

const Section13 = () => {
    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://assets.calendly.com/assets/external/widget.js"
        script.type = "text/javascript"
        script.async = true
        document.body.appendChild(script)
    })
    return (
        <div className="sect-13" id="calendly">
            <h2>Demandez une démo</h2>
            <div class="calendly-inline-widget" data-url="https://calendly.com/d/cd9-9yr-c3z/timenjoy-connect-demo?embed_domain=mytimenjoy.timenjoy.fr&embed_type=Inline" style={{ minWidth: "320px", height: "90vh" }}></div>
        </div>
    )
}

export default Section13
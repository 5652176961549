import React from 'react'

const images = [
    "/img/sect-7-0.png",
    "/img/sect-7-1.png",
    "/img/sect-7-2.png",
    "/img/sect-7-3.png"
]
const Sect7 = () => {
    return (
        <section className="sect-7">
            {images.map(a=><img src={a} alt="" />)}
        </section>
    )
}

export default Sect7
import React from 'react'
import ReactDOM from 'react-dom'
import './popupwrapper.scss'

/**
 * It renders a popup that blocks scrolling and closes when clicked outside of it
 * @returns A React component that renders a div with a class of popup-overlay and a div with a class
 * of popup-content.
 */
const Popup = ({ children, open = false, close, className, closeCross }) => {
    /* Adding a class to the body element when the popup is open and removing it when it is closed. */
    React.useEffect(() => {
        open && document.body.classList.add('block-scroll')
        return () => document.body.classList.remove('block-scroll')
    }, [open])
    /* Checking if the popup is open and if it has children. If it is not open or it does not have
    children, it returns an empty component. */
    if (open !== true || !children) return <></>
    /**
     * It returns a div with a class of popup-overlay, and a child div with a class of popup-content.
     * The popup-overlay div has an onClick event handler that calls the close function passed in as a
     * prop. The popup-content div has an onClick event handler that stops the event from propagating
     * to the popup-overlay div
     */
    const Wrapped = () => <div className={"popup-overlay " + (className || '')} onClick={e => e.stopPropagation() || close()}>
        <div className={"popup-content " + (className || '')} onClick={e => e.stopPropagation()}>
            {closeCross && <a className="close circle" onClick={close}>&times;</a>}
            {
                children instanceof React.Component || typeof children === 'function' ?
                    React.cloneElement(children, { close }) :
                    children
            }
        </div>
    </div>
    let container = document.querySelector('#popup-root')
    /* Rendering the Wrapped component to the container element. */
    return ReactDOM.createPortal(<Wrapped />, container)
}

export default Popup
import React, { useState } from 'react'
import { login, askForPasswordReset, getAccounts, getSubscription } from '../../services/UserService'
import { useMainContext } from '../../contexts/main'
import { useHistory } from 'react-router-dom'
import './Popup.scss'
import PassWordInput from '../UI/PasswordInput'

/**
 * A function that allows the user to log in.
 */
const Login = ({ switchComponent }) => {
    const [mail, setEmail] = useState()
    const [password, setPassword] = useState()
    const [error, setError] = useState()
    const [context, setContext] = useMainContext()
    const history = useHistory()

    const submit = () => {
        /* A function that allows the user to log in. */
        login(mail, password).then(res => {
            if (res.user && process.env.REACT_APP_API_ENABLE === 'true') {
                console.log('?')
                /* It's getting the accounts of the user and setting the context with the accounts. */
                getAccounts(res.company.id).then(account => {
                    if (account) {
                        setContext({ account: account, user: res.user, company: res.company, selected: account.find?.(acc => acc.app_secret) })
                        history.push('/app')
                    }
                    else {
                        setContext({})
                        setContext({ user: res.user, company: res.company })
                        history.push('/app')
                    }
                })
                /* It's getting the subscription of the user and setting the context with the
                subscription. */
                getSubscription(res.company.id).then(subscription => {
                    /* It's getting the subscription of the user and setting the context with the
                    subscription. */
                    if (subscription) {
                        history.push('/app')
                        let active = [...subscription]
                        let activeSubscription = active.filter(x => x.active == true)
                        setContext({ ...context, subscriptions: subscription, subscription: activeSubscription[0] })
                    }
                })
            }
            /* It's checking if the user is logged in and if the user is logged in, it's setting the
            context with the user and the company. If the user is not logged in, it's checking the
            error message and setting the error message. */
            else if (res.user) {
                setContext({ user: res.user, company: res.company })
                history.push('/app')
            }
            else {
                if (res.message === "missing parameter : mail") {
                    setError("Veuillez entrer un mail")
                }
                else if (res.message === "Utilisateur non reconnu, enregistrez vous.") {
                    setError("Aucun compte n'est lié à ce mail")
                }
                else {
                    setError("Mot de passe incorrect")
                }
            }
        })
    }
    /* It's a popup that can be used to login or register. */
    return (<>
        <h1>Bonjour,</h1>
        <p>Ravi de vous revoir, saisissez vos informations pour vous connecter</p>
        <div>
            <input type="text" placeholder="Adresse mail" value={mail} onChange={e => /* It's setting
            the email with the value of the input. */
            setEmail(e.target.value)}></input>
        </div>
        <div>
            <PassWordInput autocomplete="on" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} />
            {/* <input type="text" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)}></input> */}
        </div>
        <span style={{ cursor: 'pointer' }} onClick={() => /* It's a function that allows the user to
        reset his password. */
        switchComponent(Reset)}>Mot de passe oublié ?</span>
        <button onClick={() => /* It's a function that allows the user to log in. */
        submit()}>Se connecter</button>
        {error && <label style={{ color: "red" }}>{error}</label>}
    </>)
}

/**
 * It renders a form with an email input and a submit button. When the submit button is clicked, it
 * calls the `askForPasswordReset` function from the `firebase` library, which sends an email to the
 * user with a link to reset their password
 */
const Reset = ({ switchComponent }) => {
    const [mail, setEmail] = useState()
    const [error, setError] = useState()

    /**
     * It sends a request to the server to reset the password of the user with the email address mail.
     */
    function submit() {
        askForPasswordReset(mail)
            .then(res => {
                setError(false)
            })
            .catch(err => window.alert(JSON.stringify(err)))
    }
    /* It's a popup that can be used to login or register. */
    return (<>
        <h1>Un oubli ?</h1>
        <p>Réinitialiser votre mot de passe</p>
        <div>
            <input type="text" placeholder="Adresse mail" value={mail} onChange={e => setEmail(e.target.value)}></input>
        </div>
        {error === false && <>
            <p>
                C’est envoyé !<br />
                <span>
                    Vérifiez vos mails pour suivre la procédure de réinitialisation de votre mot de passe.
                </span>
            </p>
        </>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <button onClick={() => submit()}>Réinitialiser mon mot de passe</button>
        <span>Je m'en souviens, <a onClick={() => switchComponent(Login)}>me connecter</a></span>
    </>)
}
/**
 * It's a popup that can be used to login or register
 * @returns A popup component that is displayed when the user clicks on the login button.
 */
const Popup = ({ setPopup, setValue }) => {

    const [Component, setComponent] = useState(() => Login)
    const history = useHistory()

    /* It's a popup that can be used to login or register. */
    return (
        <div className="popup-overlay" onClick={e => { setPopup(false); setValue(false) }}>
            <div className="popup" onClick={e => e.stopPropagation()}>
                <div className="popup-elements">
                    <Component switchComponent={a => setComponent(() => a)} />
                </div>
                <div className="right-elements">
                    <h1>Pas de compte ?</h1>
                    <p>My Timenjoy vous permet de gérer, distribuer et communiquer vos évènements sur une seule et unique plate-forme. </p>
                    <button onClick={() => history.push('/register')}>S'inscrire gratuitement</button>
                </div>
            </div>
        </div>
    )
}

export default Popup
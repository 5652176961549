import React from 'react'
// import { useHistory } from 'react-router-dom'
import Button from '../../Home_api/sections/button'

const Sect3 = () => {
    // const history = useHistory()
    return (
        <section className="sect-3">
            <img src="/img/perf-2.png" alt="mytimenjoy" />
            <div className="text">
                <h2>Créez des campagnes<br />multicanales en un clic</h2>
                <p>
                Créez instantanément des campagnes multicanales personnalisées pour promouvoir vos évènements et entrez en contact avec les personnes les plus intéressées. Nous diffusons ainsi directement vos évènements sur un grand nombre de canaux tels que les solutions Timenjoy, notre réseau de partenaires, les Réseaux Sociaux et moteurs de recherches.
                </p>
                {/* <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>Référencer mon évènement</a> */}
                <Button text="Prendre rendez-vous"/>
            </div>
        </section>
    )
}

export default Sect3

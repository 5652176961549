import React from 'react'
import { useHistory } from 'react-router-dom'
import Header from './header'
import Button from './button'
const Hero = () => {
    const history = useHistory()
    const ref = React.useRef(null)
    const [fixedHeader, setF] = React.useState(false)
    React.useEffect(() => {
        new IntersectionObserver(
            ([entry]) => console.log('entry', entry) || setF(!Math.floor(entry.intersectionRatio)),
            { threshold: 1 }
        ).observe(ref.current)
    }, [])
    return (
        <>
            {fixedHeader && <Header fixed />}
            <section className="hero">
                <div className="text" ref={ref}>
                    <h1>Timenjoy Connect</h1>
                    <h3>La promotion de votre destination à travers les évènements.</h3>
                    <h5>La plus grande base de données d’évènements culturelles et touristiques à portée de main.<br/>Grâce à l’API Timenjoy Connect, intégrer l’ensemble de l’offre évènementielle et touristique de votre territoire directement sur vos solutions digitales. </h5>
                    {/* <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>S'inscrire</a> */}
                    <Button />
                </div>
                <img src="/img/img-sect-1@3x.png" alt="mytimenjoy" />
            </section>
            {/* <section className="customer noscrollbar">
                <img src="/img/partners/nice-jazz.png" alt="timenjoy partner" />
                <img src="/img/partners/cabaret.png" alt="timenjoy partner" />
                <img src="/img/partners/metropole.png" alt="timenjoy partner" />
                <img src="/img/partners/sud-concert.png" alt="timenjoy partner" />
                <img src="/img/partners/crossover.png" alt="timenjoy partner" />
                <img src="/img/partners/chapiteau.png" alt="timenjoy partner" />
                <img src="/img/partners/sharks.png" alt="timenjoy partner" />
                <img src="/img/partners/directo.png" alt="timenjoy partner" />
                <img src="/img/partners/ffr.png" alt="timenjoy partner" />
                <img src="/img/partners/fff.png" alt="timenjoy partner" />
            </section> */}
        </>
    )
}

export default Hero

import React from 'react';
import Hero from './sections/hero'
import Sect2 from './sections/sect2'
import Sect3 from './sections/sect3'
import Sect4 from './sections/sect4'
import Sect5 from './sections/sect5'
import Sect6 from './sections/sect6'
import Sect7 from './sections/sect7'
import Sect8 from './sections/sect8'
import Sect9 from './sections/sect9'
import Sect10 from './sections/sect10'
import Sect11 from './sections/sect11'
import Sect12 from '../Home/sections/sect12'
import Sect13 from './sections/sect13'
import Sect15 from './sections/sect15'
import Header from './sections/header'
import '../Home/home.scss'
import './home.scss'

const Home = () => {
    React.useEffect(() => {
        document.querySelector('#root').style = "height:unset;display:unset;overflow:unset;"
        return () => { document.querySelector('#root').style.height = "height:100vh;display:flex;overflow:hidden;" }
    }, [])
    return (
        <>
            <Header/>
            <main className='perf'>
                <Hero />
                {/* <Sect2 /> */}
                <Sect3 />
                <Sect4 />
                <Sect5 />
                <Sect6 />
                <Sect7 />
                <Sect8 />
                <Sect9 />
                <Sect10 />
                <Sect13/>
                <Sect11 />
                <Sect12 text={[
                    "Timenjoy Performance permet aux professionnels de l’évènementiel de créer des campagnes de communication multicanale afin de toucher le bon public, au bon endroit et au bon moment.",
                    "Mytimenjoy est une innovation soutenue par la French Tech, BPI, le WEB Summit & les CES de Las VEGAS."
                ]} />
            </main>
        </>
    )
}

export default Home;
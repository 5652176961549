import React from 'react'
// import { useHistory } from 'react-router-dom'
import Header from './header'
import Button from '../../Home_api/sections/button'

const Hero = () => {
    // const history = useHistory()
    const ref = React.useRef(null)
    const [fixedHeader, setF] = React.useState(false)
    React.useEffect(() => {
        new IntersectionObserver(
            ([entry]) => console.log('entry', entry) || setF(!Math.floor(entry.intersectionRatio)),
            { threshold: 1 }
        ).observe(ref.current)
    }, [])
    return (
        <>
            {fixedHeader && <Header fixed />}
            <section className="hero">
                <div className="text" ref={ref}>
                    <h1>Communiquez<br />votre évènement</h1>
                    <h3>Au bon public, au bon endroit et au bon moment.</h3>
                    <h5>Communiquez vos évènements chaque jour à des milliers d’utilisateurs intéressés sur une multitude de canaux grâce à une solution innovante et clés en main.</h5>
                    {/* <a className="demo" href="/register" onClick={e => { e.preventDefault(); history.push('/register') }}>Demander une démo</a> */}
                    <Button text="Prendre rendez-vous"/>
                </div>
                <img src="/img/perf-hero.jpg" alt="mytimenjoy" />
            </section>
            <section className="customer-head">
                <h2>Plus de 150 organisateurs font confiance à Timenjoy</h2>
            </section>
            <section className="customer noscrollbar">
                <img src="/img/partners/nice-jazz.png" alt="timenjoy partner" />
                <img src="/img/partners/cabaret.png" alt="timenjoy partner" />
                <img src="/img/partners/metropole.png" alt="timenjoy partner" />
                <img src="/img/partners/sud-concert.png" alt="timenjoy partner" />
                <img src="/img/partners/crossover.png" alt="timenjoy partner" />
                <img src="/img/partners/chapiteau.png" alt="timenjoy partner" />
                <img src="/img/partners/sharks.png" alt="timenjoy partner" />
                <img src="/img/partners/directo.png" alt="timenjoy partner" />
                <img src="/img/partners/ffr.png" alt="timenjoy partner" />
                <img src="/img/partners/fff.png" alt="timenjoy partner" />
            </section>

        </>
    )
}

export default Hero

import React from 'react'
import { useHistory } from 'react-router-dom';
import './guidelines.scss'

/**
 * It returns a div with a class of tnj-switch and a class of on or off depending on the value prop,
 * and when clicked, it calls the onChange prop with the opposite of the value prop
 * @returns A div with a className of tnj-switch and on or off depending on the value of the value
 * prop.
 */
const Switch = ({ value, onChange }) => {
  return (
    <div className={"tnj-switch " + (value ? 'on' : 'off')} onClick={() => onChange(!value)}>
      <div />
    </div >
  )
}

/**
 * > It renders a button that toggles a red outline around all elements on the page
 * @returns A component that renders a div with a class of guidelines.
 */
const DesignGuidelines = () => {
  const ref = React.useRef(null);
  const history = useHistory()
  /* Creating a state variable called params and setting it to the value of the search key in the
  location object of the history object. */
  const params = React.useMemo(() => new URLSearchParams(history.location.search), [history])
  const [visible, setV] = React.useState(true)
  const [movable, setM] = React.useState(false);
  /* Creating a state variable called activated and setting it to true. */
  const [activated, setA] = React.useState(true);
  /* Setting the initial value of the position state to the value of the guidelines key in
  localStorage, or if that is not set, to an object with a top of 20 and a left of the width of
  the screen minus 200. */
  const [position, setP] = React.useState(JSON.parse(localStorage.guidelines || null) || {
    top: 20,
    left: window.screen.width - 200,
  });
  /* Adding an event listener to the window that will set the visible state to true when the mouse is
  clicked. */
  React.useEffect(() => {
    if (visible) return
    const show = () => setV(true)
    window.addEventListener('mousedown', show, { once: true })
    return () => window.removeEventListener('mousedown', show)
  }, [visible])
  /* Saving the position of the guidelines to localStorage so that it can be restored when the page
  is reloaded. */
  React.useEffect(() => {
    localStorage.setItem('guidelines', JSON.stringify(position))
  }, [position])
  /* Adding an event listener to the window that will set the position state to the mouse position
  when the mouse is moved. */
  React.useEffect(() => {
    function move(e) {
      setP({
        top: e.clientY - 10,
        left: e.clientX - 10,
      });
    }
    if (movable) window.addEventListener("mousemove", move);
    if (!ref.current) {
      if (movable) return () => window.removeEventListener("mousemove", move);
      else return;
    }
    ref.current.addEventListener(
      "mouseup",
      () => {
        setM(false);
      },
      { once: true }
    );
    ref.current.addEventListener(
      "mousedown",
      () => {
        setM(true);
      },
      { once: true }
    );
    if (movable) return () => window.removeEventListener("mousemove", move);
  }, [movable]);

  /* Checking if the guidelines key is in the search key of the location object of the
  history object. If it is, it returns a div with a class of guidelines and a switch that toggles
  the outline of all elements on the page. If it is not, it returns an empty component. */
  if (params.has("guidelines"))
    return (
      <>
        <div
          ref={ref}
          className="guidelines"
          style={{
            position: "fixed",
            ...position,
            zIndex: 1_000_000,
            backgroundColor: "white",
            padding: 8,
            borderRadius: 5,
            boxShadow: "0px 0px 5px rgba(0,0,0,.5)",
            userSelect: 'none',
            ...(!visible ? { display: 'none' } : {})
          }}
          onDoubleClick={() => setV(false)}
        >
          Guidelines <Switch value={activated} onChange={setA} />
        </div>
        {activated && (
          <style>
            {"*:not(.guidelines, .guidelines *) { outline: 1px solid red; }"}
          </style>
        )}
      </>
    );
  else return <></>;
};

export default DesignGuidelines
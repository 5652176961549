import React from 'react';
import Hero from './sections/hero'
import Sect2 from './sections/sect2'
import Sect3 from './sections/sect3'
import Sect4 from './sections/sect4'
import Sect5 from './sections/sect5'
import Sect6 from './sections/sect6'
import Sect7 from './sections/sect7'
import Footer from '../Home/sections/sect12'
import Header from './sections/header'
import '../Home/home.scss'
import './home.scss'

const Home = () => {
    return (
        <>
            <Header />
            <main className="studio">
                <Hero />
                <Sect2 />
                <Sect3 />
                <Sect4 />
                <Sect5 />
                <Sect6 />
                <Sect7 />
                <Footer text={[
                    "Timenjoy Studio, l’agence de communication 360 qui permet aux professionnels de l’évènementiel de sublimer leur évènements en ligne."
                ]} />
            </main>
        </>
    )
}

export default Home;